<template>
  <v-container class="mt-3 px-sm-10 px-3" fluid>
    <PageHeader
      header-text="Your Contacts"
      regular-button-text="Create a segment"
    >
      <template #subheader>
        <div v-if="contacts_total === null">
          <skeleton-loader-vue :width="320" :height="21.5" animation="wave" :radius="4" rounded />
        </div>
        <div v-else-if="!segment_is_reset && segment && segment.name">
          There are currently <span class="blue-highlight-text">{{ contacts_total.toLocaleString() }}</span> contacts in your "{{ segment.name }}" segment
        </div>
        <div v-else>
          There are currently <span class="blue-highlight-text">{{ contacts_total.toLocaleString() }}</span> contacts in your audience
        </div>
      </template>
      <template #outlined-button>
        <v-btn
          v-if="$store.getters['user/isReadOnly'] && contacts_total > 0"
          class="my-1 page-header-btn"
          width="185px"
          height="46px"
          :block="$vuetify.breakpoint.xsOnly"
          tile
          outlined
          @click="exportDialog = true"
        >
          {{ contacts_total > 0 ? `Export all (${contacts_total.toLocaleString()})` : '' }}
        </v-btn>
        <skeleton-loader-vue
          v-else-if="contacts_total === null"
          :width="185"
          :height="46"
          :radius="4"
          rounded
          animation="wave"
        />

        <v-dialog
          v-model="exportDialog"
          scrollable
          max-width="560px"
        >
          <ExportDialog
            v-if="exportDialog"
            header="Export Contacts"
            :fields-to-export="contactExportFields"
            @export="handleExportClick"
            @dismiss="exportDialog = false;"
          >
            <template #top>
              <div class="mb-6" style="color: #000;">
                Exporting <span class="blue-highlight-text">{{ contacts_total.toLocaleString() }}</span> contacts
              </div>
            </template>
          </ExportDialog>
        </v-dialog>
      </template>
      <template #regular-button>
        <v-btn
          v-if="$store.getters['user/hasWritePermission'] && lastSearchedCriteria.groups.length > 0"
          class="my-1 ml-sm-2 page-header-btn"
          width="185px"
          height="46px"
          tile
          color="primary"
          :block="$vuetify.breakpoint.xsOnly"
          @click="dialog = true"
        >
          Create a segment
        </v-btn>

        <v-dialog v-model="dialog" scrollable max-width="560px">
          <SegmentEdit v-if="dialog" @save="create_segment" @dismiss="dialog = false;" />
        </v-dialog>
      </template>
    </PageHeader>

    <v-row v-if="segment && show_segment_description">
      <v-col>
        <SegmentDescription
          :segment="segment"
          :exclude_test_contacts="exclude_test_contacts"
          :query_schema="query_schema"
        >
          <template #end_of_alert>
            <div
              class="simple-text-button button--blue d-inline"
              @click="editSegmentSearchCriteria"
            >
              Edit Search Criteria
            </div>
          </template>
        </SegmentDescription>
      </v-col>
    </v-row>
    <v-row v-show="show_filters">
      <v-col>
        <SearchFiltersContainer :key="needRender" @clear="clear_all_filters">
          <div style="display: flex; flex-direction: column; width: 100%">
            <condition-group-builder
              v-for="(criteria_group, index) in criteria.groups"
              :key="index"
              :value.sync="criteria.operator"
              style="display: flex; flex-direction: row; flex-wrap: wrap"
              @add-condition="add_condition(index)"
              @remove-condition="remove_last_condition(index)"
            >
              <ConditionGroupsDivider
                v-if="index !== 0 && $vuetify.breakpoint.mdAndUp"
                v-model="criteria.operator"
                class="d-none d-md-block"
              />
              <ConditionGroupsDividerMobile
                v-else-if="index !== 0 && $vuetify.breakpoint.smAndDown"
                v-model="criteria.operator"
                class="d-block d-md-none"
              />
              <condition-builder
                v-for="(condition, idx) in criteria_group.conditions"
                :key="'condition-' + idx"
                :field.sync="condition.field"
                :operator.sync="condition.operator"
                :value.sync="condition.value"
                :query_schema="query_schema"
                :show-operator-selector="idx > 0"
                :concat.sync="criteria_group.operator"
              />
            </condition-group-builder>
          </div>
          <template #actions>
            <div
              class="condition-group-buttons d-flex flex-nowrap flex-column flex-md-row justify-center justify-md-space-between align-center pt-1"
            >
              <div
                class="simple-text-button button--blue pt-1"
                :class="{ 'pb-3': $vuetify.breakpoint.smAndDown }"
                @click="add_condition_group()"
              >
                New conditional group +
              </div>
              <v-btn
                class="custom-button custom-button--blue"
                height="34px"
                width="75px"
                depressed
                @click="search_contacts(null, true)"
              >
                Search
              </v-btn>
            </div>
          </template>
        </SearchFiltersContainer>
      </v-col>
    </v-row>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for contacts"
      :disable_search_field="show_filters || segment"
      :current-page-number="available_contacts_page_number"
      :current-page-size="available_contacts_page_size"
      :count-total="contacts_total"
      :page-sizes="[25, 50, 100]"
      :init-search-string="search_string"
      @search="search_contacts($event, true)"
      @next-page="get_available_contacts_next_page"
      @prev-page="get_available_contacts_prev_page"
      @change-page-size="available_contacts_change_page_size"
    >
      <div v-if="show_filters" class="simple-text-button button--red" @click="clear_all_filters">
        Clear All Filters
      </div>
      <div
        v-else
        class="simple-text-button text-grey cursor-pointer"
        @click="addFilters"
      >
        Add Filters
      </div>
      <div class="test-contacts ml-5">
        <v-checkbox
          v-model="exclude_test_contacts"
          label="Exclude Test contacts"
          color="#66788e"
          @change="search_contacts(null, true)"
        />
      </div>
    </SearchFieldRowWithPagination>

    <v-row>
      <v-col class="pt-0">
        <SearchResultsTable
          v-if="contacts.length > 0"
          :contacts="contacts"
          :table-fields="table_fields"
          :sort="sort"
          @sort="sort_contacts"
        />
        <NoResultsTable
          v-if="contacts.length === 0"
          :table-fields="table_fields"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchFiltersContainer from "@/views/Contacts/components/SearchFiltersContainer";
import ConditionBuilder from "@/views/Contacts/components/ConditionBuilder";
import ConditionGroupBuilder from "@/views/Contacts/components/ConditionGroupBuilder";
import ConditionGroupsDivider from "@/views/Contacts/components/ConditionGroupsDivider";
import ConditionGroupsDividerMobile from "@/views/Contacts/components/ConditionGroupsDividerMobile";
import SearchResultsTable from "@/views/Contacts/components/SearchResultsTable";
import NoResultsTable from "@/views/Contacts/components/NoResultsTable";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import PageHeader from "@/sharedComponents/PageHeader";
import SegmentEdit from "@/views/Segments/components/SegmentEdit";
import SegmentDescription from "@/views/Contacts/components/SegmentDescription"
import ExportDialog from "@/sharedComponents/ExportDialog";

export default {
  name: "SearchView",
  metaInfo: {
    title: 'Search'
  },
  components: {
    SegmentEdit,
    ConditionGroupBuilder,
    ConditionBuilder,
    SearchFiltersContainer,
    ConditionGroupsDivider,
    SearchResultsTable,
    NoResultsTable,
    ConditionGroupsDividerMobile,
    SearchFieldRowWithPagination,
    PageHeader,
    SegmentDescription,
    ExportDialog,
  },
  props: {
    searchId: {
      default: "",
      type: String,
    },
    outer_criteria: {
      default: "",
      type: String,
    },
    outer_search_string: {
      default: "",
      type: String,
    },
    outer_exclude_test_contacts: {
      default: "true",
      type: String,
    }
  },
  data() {
    return {
      search_string: '',
      dialog: false,
      exportDialog: false,
      contactExportFields: [],
      query_schema: {},
      criteria: {
        'operator': 'AND',
        'groups': []
      },
      lastSearchedCriteria: {
        'operator': 'AND',
        'groups': []
      },
      contacts: [],
      contacts_available: [],
      contacts_total: null,
      available_contacts_page_number: 1,
      available_contacts_page_size: 25,
      show_filters: false,
      show_segment_description: false,
      exclude_test_contacts: true,
      segment_is_reset: false,
      sort:{createdAt:'desc'},
      table_fields: [
        { label: "ID", attribute: "id" },
        { label: "First Name", attribute: "firstName" },
        { label: "Last Name", attribute: "lastName" },
        { label: " ", attribute: "isTest" },
        { label: "Email", attribute: "displayContactEmail.emailAddress" },
       // { label: "Street Address", attribute: "defaultContactLocation.addressLine1" },
        { label: "City", attribute: "defaultContactLocation.city" },
        { label: "State", attribute: "defaultContactLocation.state" },
       // { label: "Zip", attribute: "defaultContactLocation.postalCode" },
        { label: "Created Date", attribute: "createdAt" },
      ],
      segment: null,
      needRender: 0,
    };
  },
  computed: {
    available_search_entities: {
      get() {
        return Object.keys(this.query_schema);
      },
    },
    search_fields: {
      get() {
        return this.table_fields.map((item) => item.attribute);
      },
    },
  },
  async created() {
    await this.get_query_schema();
    await this.getContactExportFields();

    if (this.outer_exclude_test_contacts) {
      this.exclude_test_contacts = this.outer_exclude_test_contacts === 'true';
    }

    if (this.searchId) {
      await this.get_saved_search(this.searchId);
      this.show_filters = true;

      await this.search_contacts();

      return;
    }

    if (this.outer_criteria) {
      this.criteria = JSON.parse(this.outer_criteria)
      this.show_filters = true
    }

    if (this.$route.query.segmentId) {
      const segmentId = this.$route.query.segmentId;
      this.segment = (await this.$rest.segment.get_resource(segmentId)).data;
      if (!this.segment) {
        return;
      }

      let excludeTestContacts = this.segment.criteria?.excludeTestContacts;
      if (excludeTestContacts === null || excludeTestContacts === undefined) {
        excludeTestContacts = true;
      }

      if (excludeTestContacts !== this.exclude_test_contacts) {
        this.exclude_test_contacts = excludeTestContacts;
      }
      this.criteria = JSON.parse(JSON.stringify(this.segment.criteria))

      this.show_segment_description = true;
    }

    await this.search_contacts(this.outer_search_string);
  },
  methods: {
    clear_filters() {
      this.criteria = {
        'operator': 'AND',
        'groups': []
      };
      this.$router.replace({ params: { searchId: null} });
    },
    async clear_all_filters() {
      this.show_filters = false
      this.clear_filters();
      this.segment_is_reset = true
      await this.search_contacts(null, true)
    },
    // Methods to work with pagination buttons
    async available_contacts_change_page_size(size) {
      this.available_contacts_page_size = size;
      this.available_contacts_page_number = 1;
      await this.search_contacts();
    },
    async get_available_contacts_next_page() {
      this.available_contacts_page_number += 1;
      await this.search_contacts();
    },
    async get_available_contacts_prev_page() {
      this.available_contacts_page_number -= 1;
      await this.search_contacts();
    },
    async get_query_schema() {
      this.query_schema = (await this.$rest.contact.get_search_fields()).data;
    },
    async getContactExportFields() {
      this.contactExportFields = (await this.$rest.exports.get_export_type('contact')).data?.headers ?? [];
    },
    /**
     * Methods to manipulate condition matrix model
     * So, there hardcoded datasamples which we need to replace before goLive
     * when we delete last condition from group - we're delete and group itself
     * */
    async add_condition_group() {
      this.criteria.groups.push({
        operator: this.criteria.groups[0]?.operator || "AND",
        conditions: [
          {
            field: "",
            operator: "",
            value: "",
          },
        ],
      });
    },
    async add_condition(group_index) {
      this.criteria.groups[group_index].conditions.push({
        field: "",
        operator: "",
        value: "",
      });
    },
    async remove_last_condition(group_index) {
      this.criteria.groups[group_index].conditions.splice(
        this.criteria.groups[group_index].conditions.length - 1,
        1
      );
      if (this.criteria.groups[group_index].conditions.length === 0) {
        this.criteria.groups.splice(group_index, 1);
      }
      this.needRender = this.needRender + 1;
    },
    //Methods to load entities lists
    async create_segment(segment) {
      segment.excludeTestContacts = this.exclude_test_contacts
      segment.addContactsToSegment = true
      if (this.search_string) {
        segment.simpleSearch = this.search_string;
      } else if (this.lastSearchedCriteria) {
          segment.criteria = {...this.lastSearchedCriteria}
      }
      await this.$rest.segment.post_resource(segment)
      await this.$router.push({name: 'SegmentsSearch'})
    },
    async sort_contacts(srt) {
      this.sort = {}
      if (srt.direction) {
        this.sort[srt.field] = srt.direction
      }

      await this.search_contacts(null, true);
    },
    async get_saved_search(id) {
      const resp = (await this.$rest.contact.get_saved_search(id)).data;
      this.criteria = { ...resp.criteria };
    },
    getSegmentId() {
      if(this.segment_is_reset || !this.segment?.id || this.search_string) return null
      return Number(this.segment?.id)
    },
    async search_contacts(search_string, resetPagination = false){
      if (resetPagination) {
        this.available_contacts_page_number = 1;
      }

      if (search_string) {
        this.search_string = search_string;
      } else if (search_string !== null) {
        this.search_string = null;
      }

      const sortString = Object.keys(this.sort).map(i => `${i === 'displayContactEmail.emailAddress' ? 'email.emailAddress' : i}:${this.sort[i]}`);

      const params = {
        groups: ["contact_display_email", "contact_default_location"],
        limit: this.available_contacts_page_size,
        page: this.available_contacts_page_number,
        segmentId: this.getSegmentId(),
        excludeTestContacts: this.exclude_test_contacts,
        sort: sortString
      };
      let body = {};

      if (this.show_segment_description && typeof this.criteria.excludeTestContacts !== 'undefined') {
        delete this.criteria.excludeTestContacts
      }

      if (this.criteria?.groups?.length) {
        body = {
          searchId: this.searchId,
          criteria: {...this.criteria},
        };
      } else if (this.search_string) {
        params.simpleSearch = this.search_string;
      }

      try {
        this.criteria.groups?.forEach(group => {
          group.conditions.forEach(condition => {
            if (condition.field === '') {
              throw new Error();
            }
          })
        });
      } catch (e) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Please select the field you want to search by or remove the empty condition',
          color: 'error',
        })
      }

      this.$rest.contact.search_collection({...params, collectionProperties: ['items']}, body).then((response) => {
        this.contacts = response.data.items;

        if (this.show_segment_description) {
          this.$router.push({...this.$route, params: { searchId: response.data.searchId }})
        } else {
          this.$router.replace({
            query: { ...this.$route.query, outer_search_string: this.search_string, outer_exclude_test_contacts: this.exclude_test_contacts ? 'true' : 'false' },
            params: response.data.searchId ? { searchId: `${response.data.searchId}` } : {}
          });
        }
      });

      this.$rest.contact.search_collection({...params, collectionProperties: ['totalCount']}, body).then((response) => {
        this.contacts_total = response.data.totalCount;
      });

      // A hack to make lastSearchedCriteria non-reactive
      if (body.criteria !== undefined) {
        this.lastSearchedCriteria = JSON.parse(JSON.stringify(body.criteria));
      }
    },
    async handleExportClick(details) {
      if (this.contacts_total <= 0) {
        return;
      }

      if (this.getSegmentId()) {
        await this.$rest.exports.post_resource({
          "exportType": "contact",
          "exportData": {
            "segmentId": this.getSegmentId(),
          },
        })
      } else {
        await this.$rest.exports.post_resource({
          "exportType": "contact",
          "exportData": {
            "simpleSearch": this.search_string,
            "searchCriteria": {
              ...this.lastSearchedCriteria,
              excludeTestContacts: this.exclude_test_contacts,
            },
            ...details
          },
        })
      }

      await this.$router.push({name: "ExportsView"})
    },
    editSegmentSearchCriteria() {
      this.show_segment_description = false;
      this.$router.push({ ...this.$route, params: { searchId: null} });

      this.addFilters()
    },
    addFilters() {
      this.segment = null;
      this.show_filters = true
      this.search_string = null;

      let query = Object.assign({}, this.$route.query);
      delete query.segmentId;
      this.$router.replace({ query });
    },
  },
};
</script>

<style lang="scss" scoped>
.test-contacts {
  ::v-deep .v-input--checkbox {
    margin-top: 4px;

    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
    }
    .v-icon {
      font-size: 18px;
    }
    .v-messages {
      display: none;
    }
  }
}
.blue-highlight-text {
  color: #2b84eb;
  font-weight: 600;
  text-decoration: none;
}

.text-grey {
  color: #a5a9b8;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
